import { Check } from "lucide-react";
import React from "react";
import { FaInstagram, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import "./TechTransform.css";
import techimage from "./../../images/techimages.png";
import harish from "./../../images/Harish-img.jpg";
import img2 from "./../../images/review-carousel.png";

const TechTransform = () => {
  return (
    <div className="container1">
      <nav className="navbar">
        <div className="nav-content">
          <h1 className="nav-title">TechTransform</h1>
          <div className="nav-links">
            <a href="#benefits">Benefits</a>
            <a href="#eligibility">Eligibility</a>
            <a href="#success">Success Stories</a>
            <button className="btn-primary">Enroll Now</button>
          </div>
        </div>
      </nav>

      <div className="hero-section">
        <div className="hero-content">
          <h1>Transform Your Career to Cloud & DevOps</h1>
          <p>
            Join our comprehensive workshop and make a successful transition
            into the high-demand field of Cloud & DevOps.
          </p>
          <button className="btn-secondary">Register Now</button>
        </div>
        <div className="hero-image">
          <img src={techimage} alt="Cloud & DevOps" />
        </div>
      </div>

      <div className="salary-section">
        <h2 className="section-title">Expected Salary Ranges</h2>
        <div className="salary-grid">
          <div className="salary-card">
            <h3>Entry Level</h3>
            <p className="salary-amount">$70,000+</p>
            <p>Per Year</p>
          </div>
          <div className="salary-card">
            <h3>Mid Level</h3>
            <p className="salary-amount">$100,000+</p>
            <p>Per Year</p>
          </div>
          <div className="salary-card">
            <h3>Senior Level</h3>
            <p className="salary-amount">$150,000+</p>
            <p>Per Year</p>
          </div>
        </div>
      </div>

      <div id="benefits" className="section">
        <h2 className="section-title">
          Benefits of Switching to Cloud & DevOps
        </h2>
        <div className="benefits-grid">
          {[
            {
              title: "High Demand",
              icon: "🚀",
              description:
                "Exceptional job security with increasing market demand",
            },
            {
              title: "Career Growth",
              icon: "📈",
              description: "Rapid career progression opportunities",
            },
            {
              title: "Global Opportunities",
              icon: "🌎",
              description: "Work with companies worldwide",
            },
          ].map((benefit, index) => (
            <div key={index} className="benefit-card">
              <div className="benefit-icon">{benefit.icon}</div>
              <h3 className="benefit-title">{benefit.title}</h3>
              <p className="benefit-description"> {benefit.description}</p>
            </div>
          ))}
        </div>
      </div>

      <div id="success" className="success-section">
        <h2 className="section-title">Success Stories</h2>
        <div className="success-grid">
          <div className="success-card">
            <div className="success-profile">
              <img src={harish} alt="John Smith" className="profile-image" />
              <div className="profile-info">
                <h3>John Smith</h3>
                <p className="role">From Java Developer to Cloud Architect</p>
              </div>
            </div>
            <p className="testimonial">
              "Made the switch 2 years ago, now earning 2x my previous salary
              and loving the work!"
            </p>
          </div>
          <div className="success-card">
            <div className="success-profile">
              <img src={img2} alt="Sarah Johnson" className="profile-image" />
              <div className="profile-info">
                <h3>Sarah Johnson</h3>
                <p className="role">From QA to DevOps Engineer</p>
              </div>
            </div>
            <p className="testimonial">
              "The transition was smooth with proper guidance. Now working with
              cutting-edge technologies."
            </p>
          </div>
        </div>
      </div>

      <div id="eligibility" className="section">
        <h2 className="section-title">Who Can Switch to Cloud & DevOps?</h2>
        <div className="eligibility-grid">
          <div className="eligibility-card">
            <h3>Technical Background</h3>
            <ul>
              {[
                "Software Developers",
                "System Administrators",
                "Network Engineers",
              ].map((item, index) => (
                <li key={index} className="eligibility-item">
                  <Check className="icon-check" /> {item}
                </li>
              ))}
            </ul>
          </div>
          <div className="eligibility-card">
            <h3>Requirements</h3>
            <ul>
              {[
                "Basic Programming Knowledge",
                "Understanding of OS Concepts",
                "Willingness to Learn",
              ].map((item, index) => (
                <li key={index} className="eligibility-item">
                  <Check className="icon-check" /> {item}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div className="enrollment-section">
        <h2 className="section-title">Enroll in the Workshop</h2>
        <form className="enrollment-form">
          <div className="form-group">
            <label>Full Name</label>
            <input
              type="text"
              placeholder="Enter your full name"
              className="form-input"
              required
            />
          </div>

          <div className="form-group">
            <label>Email Address</label>
            <input
              type="email"
              placeholder="Enter your email address"
              className="form-input"
              required
            />
          </div>

          <div className="form-group">
            <label>Phone Number</label>
            <div className="phone-input-container">
              <select className="country-select">
                <option>United States (+1)</option>
              </select>
              <input
                type="tel"
                placeholder="WhatsApp Number"
                className="form-input phone-input"
                required
              />
            </div>
          </div>

          <button type="submit" className="register-btn">
            Register Now
          </button>
        </form>
      </div>

      <footer className="footer">
        <h1>TechTransform</h1>
        <p>Transform your career with our expert guidance</p>
        <div className="footer-icons">
          <FaInstagram size={24} />
          <FaTwitter size={24} />
          <FaLinkedinIn size={24} />
        </div>
        <p>© 2025 TechTransform. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default TechTransform;
