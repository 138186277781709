import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowRight, ArrowDown } from "lucide-react";
import mission from "../../images/internship-mission-image1.png";
import certificate from "../../images/Certficate-card.png";
import Gen_Ai from "../../images/Gen-AI-Internship.png";
import Python from "../../images/Python.png";
import satya from "../../images/student_satya.jpg";
import abhisht from "../../images/student_abhisht.jpg";
import pradhuman from "../../images/student_pradhuman.jpeg";
import pramod from "../../images/student_pramod.jpeg";
import InternshipForm from "./InternshipForm";
import tick from "../../images/banner-tick.svg";

function Internship() {
  const [activeCard, setActiveCard] = useState(null);
  const applyRef = useRef(null);

  const scrollToSection = () => {
    applyRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const navigate = useNavigate();

  const handleCardClick = (path) => {
    navigate(path);
  };

  const internships = [
    {
      title: "Python Internship",
      description:
        "Learn and apply Python in real-world scenarios while working on exciting projects. Gain hands-on experience with data analysis, web development, and automation.",
      icon: (
        <img
          src={Python}
          alt="Python Internship"
          className="card-image"
          title="Python Internship"
        />
      ),
      color: "#4B8BBE",
      path: "/internship/python",
    },
    {
      title: "Gen AI Internship",
      description:
        "Explore the latest advancements in Generative AI and contribute to cutting-edge AI solutions. Work on projects involving natural language processing, computer vision, and machine learning.",
      icon: (
        <img
          src={Gen_Ai}
          alt="Gen AI Internship"
          className="card-image"
          title="Gen AI Internship"
        />
      ),
      color: "#FF6B6B",
      path: "/internship/gen-ai",
    },
  ];

  return (
    <>
      <div className="sub-banner">
        <h1 className="h1-large">About Internship</h1>
      </div>
      <div className="internship-page">
        <div className="container">
          {/* mission section */}

          <div className="mission-section">
            <div className="mission-text-and-points-section">
              <div className="mission-text-section">
                <h1>Our Core Belief</h1>
                <p>
                  To be the change agent that helps businesses harness the power
                  of the Cloud and DevOps methodologies to achieve efficiency,
                  scalability, and innovation.
                </p>
              </div>
              <div className="mission-points-section">
                <ul>
                  <li>Hands-on experience in your field of interest</li>
                  <li>Mentorship from industry professionals</li>
                  <li>Opportunities to work on live projects</li>
                  <li>Flexible working hours</li>
                </ul>
              </div>
            </div>
            <div className="mission-image-section">
              <img src={mission} alt="mission" title="mission" />
            </div>
          </div>

          {/* overview section */}

          <div className="overview-section">
            <div className="text-section">
              <h1>Why join our internship program?</h1>
              <div className="overview-explore-section">
                <p>
                  As one of our interns, you will gain hands-on experience, work
                  alongside professionals, and open doors to future oppurtunites{" "}
                </p>
                {/* <button className="apply-float btn" onClick={scrollToSection}>
                  Explore Internships
                </button> */}
              </div>
            </div>
            <div className="points-section">
              <div className="card1 point-cards">
                <div className="card-icon">
                  <img src={tick} alt="tick" title="tick" />
                </div>
                <p>Gain real-world experience in Cloud and DevOps</p>
              </div>
              <div className="card2 point-cards">
                <div className="card-icon">
                  <img src={tick} alt="tick" title="tick" />
                </div>
                <p>Develop both technical and soft skills</p>
              </div>
              <div className="card3 point-cards">
                <div className="card-icon">
                  <img src={tick} alt="tick" title="tick" />
                </div>
                <p>Build a professional network with industry experts</p>
              </div>
              <div className="card4 point-cards">
                <div className="card-icon">
                  <img src={tick} alt="tick" title="tick" />
                </div>
                <p>
                  Potential for full-time employment upon program completion
                </p>
              </div>
            </div>
          </div>

          {/* phases section */}

          <div className="phases-section">
            <h1>Program phases</h1>
            <p>
              Our internship program is designed to provide a comprehensive
              learning experience, divided into two strategic phases that faster
              growth from foundational knowledge to advanced implementation.
            </p>
            <div className="phases-container">
              <div className="phases-box-text phases-box phase-shadow-box">
                <h4>
                  Phase 1: Learning and Growth (3 months) Kickstart Your Carrer
                  Journey
                </h4>
                <p>
                  Open to graduates in Computer Science, IT, or related fields,
                  this initial phase focuses on intensive technical training,
                  mentorship, and constructive feedback. While unpaid, this
                  phase lays the crucial groundwork for your professional
                  development.
                </p>
              </div>
              <div className="phases-box-image1"></div>
              <div className="phases-box-image2"></div>
              <div className="phases-box-text phases-box">
                <h4>
                  Phase 2: Professional Development (3 months) Apply Your
                  Knowledge in Real-World Scenarios
                </h4>
                <p>
                  Interns transition into a paid position. This phase emphasizes
                  practical application through shadowing experienced
                  professionals, continued mentorship, working on proof of
                  concepts, and delivering presentations. It's designed to
                  bridge the gap between theoretical knowledge and real-world
                  application.
                </p>
              </div>
            </div>
            <div className="phases-footer">
              Throughout both phases, our goal is to nurture your skills, boost
              your confidence, and prepare you for a successful career in the
              tech industry. This structured approach ensures a well-rounded
              experience that balances learning with hands-on practice.
            </div>
          </div>

          {/* cards section */}

          <section ref={applyRef} className="internship-cards">
            <h2 className="section-title">Our Opening for Internships</h2>
            <div className="cards-container">
              {internships.map((internship, index) => (
                <div
                  key={index}
                  className={`internship-card ${
                    activeCard === index ? "active" : ""
                  }`}
                  style={{ "--card-color": internship.color }}
                  onMouseEnter={() => setActiveCard(index)}
                  onMouseLeave={() => setActiveCard(null)}
                  onClick={() => handleCardClick(internship.path)}
                >
                  <div className="card-icon">{internship.icon}</div>
                  <h3 className="card-title">{internship.title}</h3>
                  <p className="card-description">{internship.description}</p>
                  <button className="card-button">
                    Apply <ArrowRight size={16} />
                  </button>
                </div>
              ))}
            </div>
          </section>
        </div>

        {/* stories section */}

        <div className="stories-section-wrapper">
          <div className="stories-section">
            <h2>Success Stories</h2>
            <div className="stories-grid">
              <div className="stories-card">
                <div className="stories-image-section">
                  <img
                    className="storie-image"
                    src={satya}
                    alt="profile"
                    title="profile"
                  />
                  <h5>Satya Tulasi Jalandhar</h5>
                </div>
                <p>
                  The Generative AI Internship opened doors for me. I gained
                  hands-on experience and built a strong AI portfolio.
                </p>
              </div>
              <div className="stories-card">
                <div className="stories-image-section">
                  <img
                    className="storie-image"
                    src={pramod}
                    alt="profile"
                    title="profile"
                  />
                  <h5>Pramod Soni</h5>
                </div>
                <p>
                  {" "}
                  I learned so much in such a short time! The projects helped me
                  understand real-world applications.
                </p>
              </div>
              <div className="stories-card">
                <div className="stories-image-section">
                  <img
                    className="storie-image"
                    src={pradhuman}
                    alt="profile"
                    title="profile"
                  />
                  <h5>Pradhuman Soni</h5>
                </div>
                <p>
                  This internship allowed me to apply my knowledge to real-world
                  AI projects. It was challenging but incredibly rewarding!
                </p>
              </div>
              <div className="stories-card">
                <div className="stories-image-section">
                  <img
                    className="storie-image"
                    src={abhisht}
                    alt="profile"
                    title="profile"
                  />
                  <h5>Abhisht Dubey</h5>
                </div>
                <p>
                  The support and mentorship were top-notch. I couldn't have
                  asked for a better learning experience.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Certificate section */}

        <div className="certificate-section">
          <div className="certificate-text-section">
            <h1>Internship Certificate</h1>
            <h5>
              Upon successful completion of the internship, each participant
              will be awarded an official Internship Certificate. This
              certificate recognizes the skills, dedication, and hands-on
              experience gained during the program. It serves as a valuable
              addition to your professional portfolio and showcases your
              commitment to growth and learning."
            </h5>
          </div>
          <img src={certificate} alt="certificate" title="certificate" />
        </div>

        {/* Form section */}

        <div className="form-section">
          <h1>Join us as we guide you on the right path for your carrer.</h1>
          <div className="form-box">
            <h2>Apply for the Internship</h2>
            <InternshipForm />
          </div>
        </div>
      </div>
    </>
  );
}

export default Internship;
